/* fonts */
@font-face {
  font-family: 'Open Sans';
  src: url('/assets/fonts/Open_Sans/OpenSans-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url('/assets/fonts/Open_Sans/OpenSans-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}

/* fonts */
@font-face {
  font-family: 'Raleway';
  src: url('/assets/fonts/Raleway/Raleway-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Raleway';
  src: url('/assets/fonts/Raleway/Raleway-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Rose';
  src: url('/assets/fonts/Rose/Rose-Regular-0.1.0.ttf');
  font-weight: 400;
  font-style: normal;
}

/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(/assets/fonts/MaterialIcons/icons.woff2) format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

.app-wrapper{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-flow: column;
  align-items: stretch;
  -webkit-animation-duration: .25s;
  -moz-animation-duration: .25s;
  overflow-x: hidden;

}

.dm-app-wrapper{

  position: fixed;

  flex-direction: row;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex: 1;

  overflow: none;
  min-height:0;

  height: 100%;
  width: 100%;

  background: #ffffff;

  align-items: center;
  justify-content: flex-start;
}

.deal-app{
  flex: 1;
  min-height:0;
  overflow: hidden;
  flex-direction: column;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-self: stretch;
  background: #f7f7f7;

  position: relative;
}

.deal-main-view{
  flex: 1;
  min-height:0;
  flex-direction: row;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-self: stretch;
  background: #f7f7f7;
  min-width: 350px;
}

.deal-main-view-inner{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.deal-container-fill-wrapper{
  flex: 1;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-width: 800px;
  min-height: 500px;
}
.deal-container-wrapper{
  flex: 1;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}




.vh-container{
  height: 100vh;
  height: -moz-available;
  height: -webkit-fill-available;
  height: fill-available;


}

.vh-container-desktop{
  height: 100vh;

}


.dm-bottom-tab-bar{
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.deal-container{
  background: #ffffff;
  flex-direction: column;
  align-self: stretch;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex: 1;
}
.deal-right-panel-modal{
  position: fixed;
  z-index: 1;
  top: 0;
  right:0;
  bottom:0;
  width: 100%;
  max-width: 500px;
  display: flex;

}
.deal-right-panel-container{
  flex: 1;
  max-width: 400px;
  background: #f7f7f7;
  box-shadow: -1px 0px 10px rgba(0,0,0,0.1);
  flex-direction: column;
  align-self: stretch;
  z-index: 0;

  display:flex;
  overflow: auto;
}
.shadow-expanded-tabs-area{
  box-shadow: -1px 0px 10px rgba(0,0,0,0.1);

}

.deal-right-panel-container-new{
  flex: 1;
  width: 100%;
  max-width: 500px;
  background: #f7f7f7;
  box-shadow: -1px 0px 10px rgba(0,0,0,0.1);

  flex-direction: column;
  align-self: stretch;
  z-index: 0;
  display:"flex";
}

.shadow-extened-tabs-area{
  box-shadow: -1px 0px 10px rgba(0,0,0,0.1);
}

.deal-right-panel-container-new.focus{
  max-width: 1100px;
}
.deal-right-panel-container-new.expanded-side-panel{
  max-width: 750px;
}

.deal-right-panel-container-new.content{
  max-width: 767px;

}

.deal-right-panel-container.small{
  max-width: 300px;
  box-shadow: none;
}

.deal-left-panel-container{
  flex: 1;
  width: 100%;
  max-width: 500px;
  background: #f7f7f7;
  box-shadow: 1px 0px 10px rgba(0,0,0,0.2);
  height: 100vh;
  height: -moz-available;
  height: -webkit-fill-available;
  z-index: 1;

  flex-direction: column;
  align-self: stretch;

}

.deal-left-panel-container.list{
  max-width: 350px;
}



.deal-container-fill{
  background: #ffffff;
  flex-direction: column;
  align-self: stretch;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex: 1;
}
.deal-full-app .dark-container{
  background: #242526;
  flex: 1;
}
.deal-keyboard-scroll{
  width: 100%;
  margin: 0px auto;

}
.deal-web-container, .deal-web-container-small{
  max-width: 700px;
  width: 100%;
  margin: 0px auto;
}
.deal-web-container-large{
  max-width: 900px;
  margin: 0px auto;
}
.deal-web-container-xl{
  max-width: 1200px;
  margin: 0px auto;
}
.deal-web-container-2xl{
  max-width: 1500px;
  margin: 0px auto;
}
.deal-card{
  background: #fff;
  margin: 5px;
  border: 1px solid #ddd;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;

  align-self: stretch;
  border-radius: 5px;
  position: relative;
}
.deal-full-app .dark-card{
  background: #2B2D30;
}
.deal-card-body{
  padding: 25px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
}
.deal-primary-button{
  font-weight: 200;
  color: #fff !important;
  font-family: "Raleway", sans-serif;
  background: #31cce5;
  padding: 20px;;
  font-size: 20px;
  border-radius:5px;
  text-align: center;
  border:none !important;
  align-self: stretch;
  outline: none !important;
  text-decoration: none !important;
  align-items: center;
  display: block;
  line-height: normal;

  background: #31cce5; /* Old browsers */

  border-radius: 30px;
  overflow: hidden;
  background: -moz-linear-gradient(left, #31cce5 0%, #4EE8C3 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #31cce5 0%,#4EE8C3 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #31cce5 0%,#4EE8C3 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#31cce5', endColorstr='#4EE8C3',GradientType=0 );
  background-image: linear-gradient(to right bottom, #31cce5, #4EE8C3);
}
.deal-full-app .dark-primary-button{
  background: #187887; /* Old browsers */

  background: -moz-linear-gradient(-45deg, #187887 0%, #008080 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #187887 0%,#008080 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #187887 0%,#008080 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#187887', endColorstr='#008080',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
.deal-primary-button.deal-small-button{
  padding: 10px;
  font-size: 14px;
  align-self: inherit;

}
.deal-primary-button:hover,
.deal-primary-button:active,
.deal-primary-button:focus{
  background: #1ddbd9;
  cursor: hand;
  cursor: pointer;
}
.deal-full-app .dark-primary-button:hover,
.deal-full-app .dark-primary-button:active,
.deal-full-app .dark-primary-button:focus{
  background: #1f8a9b;
  cursor: hand;
  cursor: pointer;
}
.deal-full-app .deal-primary-button-dark:hover,
.deal-full-app .deal-primary-button-dark:active,
.deal-full-app .deal-primary-button-dark:focus{
  background: #3298a8;
  cursor: hand;
  cursor: pointer;
}

.deal-secondary-button{
  font-weight: 200;
  border-radius:5px;
  color: #2C2C2C;
  font-family: "Raleway", sans-serif;
  background: #fff;
  padding: 20px;;
  font-size: 20px;
  text-align: center;
  border:none !important;
  align-self: stretch;
  outline: none !important;
  text-decoration: none !important;
  align-items: center;
  line-height: normal;
  border-radius: 30px;
  overflow: hidden;
}

.deal-full-app .dark-secondary-button{

  color: #187887;
  background: #424242; /* Old browsers */

  background: -moz-linear-gradient(-45deg, #424242 0%, #424242 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #424242 0%,#424242 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #424242 0%,#424242 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#424242', endColorstr='#424242',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

.deal-secondary-button:hover,
.deal-secondary-button:active,
.deal-secondary-button:focus{
  background: #fff5ff;
  cursor: hand;
  cursor: pointer;
}
.deal-full-app .dark-secondary-button:hover,
.deal-full-app .dark-secondary-button:active,
.deal-full-app .dark-secondary-button:focus{
  background: #565656;
  cursor: hand;
  cursor: pointer;
}

.deal-delete-button{
  font-weight: 200;
  border-radius:5px;
  color: #fff !important;
  font-family: "Raleway", sans-serif;
  background: #fff;
  padding: 20px;;
  font-size: 20px;
  text-align: center;
  border:none !important;
  align-self: stretch;
  outline: none !important;
  text-decoration: none !important;
  align-items: center;
  line-height: normal;
  border-radius: 30px;
  overflow: hidden;
  background: #ffffff; /* Old browsers */
  background: -moz-linear-gradient(left, #B24C63 0%, #a42f49 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #B24C63 0%,#a42f49 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #B24C63 0%,#a42f49 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#B24C63', endColorstr='#a42f49',GradientType=0 );
}

.deal-delete-button:hover,
.deal-delete-button:active,
.deal-delete-button:focus{
  background: #a42f49;
  cursor: hand;
  cursor: pointer;
}


.deal-title{
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  font-family: "Raleway", sans-serif;
  color: #2C2C2C;
}
.deal-full-app .dark-title{
  color: #187887;
}
.deal-copy-title{
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  font-family: "Open Sans", sans-serif;
  color: #2C2C2C;
}

.dm-markdown{
  font-size: 14px;
}

.dm-markdown p, .dm-markdown ul, .dm-markdown ol, .dm-markdown ul li, .dm-markdown ol li{
  font-size: 14px;
  line-height: 18px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}
.dm-markdown p{
  margin: 0px;
  margin-bottom: 10px;
}
.dm-markdown p:last-child{
  margin-bottom: 0px;

}
.dm-markdown pre{
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.3);
  padding: 10px;
  white-space: pre-wrap;
}
.dm-markdown.dark_mode pre{
  background: rgba(0, 0, 0, 0.3);
}


.dm-markdown table {
  border-collapse: collapse;
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
  border-color: rgba(255, 255, 255, 0.3);
  margin: 10px 0px;

}

.dm-markdown th, .dm-markdown td {
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 10px;
  line-height: 18px;
  text-align: left;
}

.dm-markdown th {
  background-color: rgba(255, 255, 255, 0.3);
}

.dm-markdown tr:nth-child(even) {
  background-color: rgba(255, 255, 255, 0.3);
}

.dm-markdown.dark-mode{
  color: rgba(255, 255, 255, 0.6);
}

.deal-copy{
  font-size: 14px;
  line-height: 18px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  color: #2C2C2C;
}
.deal-rich-text{
  font-size: 14px;
  line-height: 18px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}
.deal-rich-text-dark-mode p{
  font-size: 14px;
  line-height: 18px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.6);
}
.deal-rich-text p{
  font-size: 14px;
  line-height: 18px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}
.deal-full-app .dark-copy{
  color: #EEE;
}
.deal-bold{
  font-weight: bold;
}
.deal-wrapper{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
}

.deal-center-left{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.deal-center-right{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  max-width: 950px;
  width: 100%;
  margin: 0px auto;
}

.deal-row{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.deal-mobile-row{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.deal-col{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}

.deal-center-center{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.deal-split{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.deal-stretch{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;

  flex: 1;
  align-self: stretch;
}

.deal-switch-button{

}

.deal-button{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  padding: 0px;

  align-self: stretch;
  background: transparent;
  text-decoration: none !important;
  color: inherit !important;


}
.deal-button:hover{
  cursor: pointer;
  cursor: hand;
  /*opacity: 0.8;*/
}
.deal-button *{

}
.deal-image{
  object-fit: cover;
}
.deal-image-contain{
  object-fit: contain;
}
.deal-scroll{
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.deal-scroll-horizontal{
  overflow: hidden;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-flow: row nowrap;
}

.deal-map-wrapper{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.deal-designer-container{
  position: relative;
  overflow: hidden;
}
.deal-designer-overlay,
.deal-designer-overlay-transparent{
  position: absolute;
  top: 0;
  left:0;
  background:transparent;
  width: 100%;
  height: 100%;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none !important;
}
.deal-map-container{
  position: relative;
  overflow: hidden;
}



.deal-map-overlay,
.deal-map-overlay-transparent{
  position: absolute;
  top: 0;
  left:0;
  background:transparent;
  width: 100%;
  height: 100%;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none !important;
}
.deal-map-overlay-transparent{
  pointer-events: none;
}
.deal-map-overlay .map-marker{
  width: 40px;
  height: 40px;
  margin-bottom: 25px;
}

.deal-dashed-card{
  background: transparent;
  margin: 5px;

  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;

  border-radius: 5px;
  overflow: hidden;
  position: relative;

  border: 1px dashed #ddd;
}

.deal-full-app .dark-dashed-border{
  border: 1px dashed #424242;
}

.dm-select-wrapper{
  position: relative;
}
.dm-select-wrapper select{
  position: absolute;
  opacity: 0;
  top:0;
  bottom:0;
  left:0;
  right:0;
  width:100%;
  height:100%;
  -webkit-appearance: menulist-button;
}
.dm-list{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.dm-list > div, .dm-list > a{
  width: 100%;
  text-decoration: none !important;
}

.dm-list-1 > div, .dm-list-1 > a{
  width: 100%;
  text-decoration: none !important;
}
.dm-list-2 > div, .dm-list-2 > a{
  width: 50%;
  text-decoration: none !important;
}


.deal-modal-overlay{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0, 0.7);
  -webkit-animation-duration: .5s;
  -moz-animation-duration: .5s;
  z-index: 1;
}

.deal-row>.deal-modal-overlay{
  z-index: 2;
}

.deal-modal{
  margin: 10px;
  max-width: 400px;
  width: 100%;
  padding: 20px;
  -webkit-animation-duration: .5s;
  -moz-animation-duration: .5s;
}

.deal-modal-container{
  background: #f7f7f7;
  margin: 7px;
  padding: 0px;
  box-shadow: 1px 1px 5px rgba(0,0,0,0.2);

  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;

  border-radius: 5px;
  position: relative;

  overflow: hidden;
  overflow-y: auto;
  max-height: 90%;


}

.deal-bottom-bar-padding{
  height: 200px;
  width: 100%;
}
.deal-bottom-bar{
  position: absolute;
  bottom:0;
  left: 0;
  right: 0;
}

.deal-form{
  margin-bottom: 0px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
}

.deal-video-container{
  position: relative;
  width: 100%;
  height:90vh;
  min-height: 700px;
}
.deal-video-wrapper{
  position: absolute;
  overflow: hidden;
  width: 100%;
  height:100%;
  top: 0;
  left: 0;
}
.deal-video-inner{

  position: absolute;
  width: 100%;
  height:100%;
  top: 0;
  left: 0;
  background-color: rgba(	49, 204, 229, 0.9);

  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;

}
video.deal-background-video{
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.deal-start{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;

  overflow: hidden;
  height: 100vh;
  height: -moz-available;
  height: -webkit-fill-available;
  flex: 1;
}
.deal-start-left{
  flex: 1;
  align-self: stretch;
  overflow: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
.deal-start-right{
  flex: 1;
  align-self: stretch;
  width: 100%;
  max-width: 500px;
  background: #fff;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;

  overflow: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
.side-panel-container{

  flex-direction: row;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;


  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  min-height: 0;
  align-self: stretch
}
.deal-side-panel{
  background: #fff;
  align-self: stretch;
  width: 100%;
  max-width: 350px;
  border-left: 1px solid #ddd;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.deal-full-app .dark-side-panel{
  background: #242526;
  border-left: 1px solid #424242;
}



@media(max-width: 767px){
  /* scrolling */
  html, #app, #app>div, body, #root{
    height: auto;
    -webkit-overflow-scrolling: touch;

  }
  [data-reactroot]{height: auto !important; }

}

.deal-iframe{
  position: relative;
  background: "#f7f7f7"
}
.deal-iframe iframe html{
  -ms-transform: scale(0.25);
    -moz-transform: scale(0.25);
    -o-transform: scale(0.25);
    -webkit-transform: scale(0.25);
    transform: scale(0.25);

    -ms-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -o-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
}

.deal-training-video{
  margin: 7px auto;
  box-shadow: 1px 1px 5px rgba(0,0,0,0.2);

  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;

  align-self: stretch;
  border-radius: 5px;
  position: relative;

}
.deal-training-video, .deal-training-video iframe{
  border-radius: 5px;
  width: 536px;
  height: 301px;
}



/* new deal view */
.deal-single-deal-view:not(.limited-info){
  flex-direction: row;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex: 1;

  max-width: 800px;
  margin: 0px auto;

  align-items: flex-start;
  justify-content: flex-start;

}
.deal-single-deal-view.limited-info{
  max-width: 550px;
  margin: 0px auto;

}

.deal-single-deal-view:not(.limited-info) .deal-single-deal-view-left{
  flex: 1;
  max-width: 386px;

}

.deal-single-deal-view:not(.limited-info) .deal-single-deal-view-right{
  flex: 1;
  width: 50%;
  max-width: 386px;

}

.ReactTable .rt-thead {
  overflow-y: scroll;
}

.deal-hover-icon .material-icons{
  opacity: 0;
}

.deal-hover-icon:hover .material-icons{
  opacity: 1;
}

.deal-hover-preview .deal-preview{
  opacity: 0;
}

.deal-hover-preview:hover .deal-preview{
  opacity: 1;
}

.deal-sliding-panel{
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  border-radius: 20px 20px 0px 0px;
  background: #ffffff;
  box-shadow: 1px 10px 25px rgba(0,0,0,0.3);
}

.deal-sliding-panel.closed{

}
.deal-popover .deal-copy{
  font-size: 10px;
}

.deal-popover .deal-copy{
  font-size: 12px;
  line-height: 16px;
}

.deal-popover .deal-title{
  font-size: 14px;
  line-height: 18px;
}
.deal-popover input{
  font-size: 12px;
  line-height: 16px;
  height: 30px
}
.deal-popover label {
  font-size: 8px
}
.deal-popover .deal-card-body{
  padding: 10px !important;
}
.deal-popover ::-webkit-input-placeholder{
  font-size: 12px;
  line-height: 16px;
}
.deal-popover .material-icons{
  font-size: 16px !important;
  line-height: 16px;
}

.deal-spin{
  line-height: 14px;
}

.dm-user-tabbed-items{
  flex-direction: row
}
.dm-user-tabs{
  width: 100px;
}

.dm-activity-slider{
  transition: height 0.15s ease-in;
}
.dm-progress-bar{
  transition: width 0.15s ease-in;
}

.deal-mailer-preview p{
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  margin: inherit;
  padding: inherit;
  font-family: inherit;
  font-weight: inherit;
}
.dm-placeholder{
  opacity: .3
}
.dm-drag:hover{
  cursor: grab;
}

#font-picker{
  width: 100%;
}
#font-picker .dropdown-button{
  background: #fff;
  border-radius: 5px;
}
.dark_mode #font-picker .dropdown-button{
  background: #323F4B;
  color: #fff;
}

.chrome-picker{
  box-shadow: none !important;
}
